body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerTextClass {
  font-size: smaller;
}

.oreBlendGroupStyle {
  background-color: #bdc3c7;
  font-size: 12pt;
}

.crusherGroupStyle {
  background-color: #95a5a6;
  font-size: 12pt;
}

.millGroupStyle {
  background-color: #3498db;
  font-size: 12pt;
}

.gradesGroupStyle {
  background-color: #e67e22;
  font-size: 12pt;
}

.waterBalanceGroupStyle {
  background-color: #16a085;
  font-size: 12pt;
}

.oxygenFlowGroupStyle {
  background-color: #9b59b6;
  font-size: 12pt;
}

.envGroupStyle {
  background-color: #27ae60;
  font-size: 12pt;
}

.datesGroupStyle {
  background-color: #34495e;
  color: antiquewhite;
}

.headerStyle {
  font-size: 12pt;
}
