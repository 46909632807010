.leaflet-control-attribution{
    display: none;
}
.leaflet-control-container .leaflet-right{
    margin-right: 71px;
}
.leaflet-bar .custom-filter-btn{
    background-color: white;
    height: 43px;
    width: 30px;
    border:1px solid #dddddd;
    color: #9e9c9c;
}
.leaflet-bar .custom-filter-btn:hover{
    background-color: white;
    border-color: #dddddd;
}